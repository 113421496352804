<div>
  <div class="title">
    <app-breadcrumb [name]="order?.faultNumber"></app-breadcrumb>
  </div>
  <div class="tabs">
    <div
      class="tab"
      (click)="tab = 'details'"
      [class.active]="tab == 'details'">
      {{ 'orders-front.item.tabs.order_details' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'documents'"
      [class.active]="tab == 'documents'"
      *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.documents' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'costs'"
      [class.active]="tab == 'costs'"
      *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.costs' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'packages'"
      [class.active]="tab == 'packages'"
      *ngIf="Auth.isViewAllowed(['ROLE_SHIPMENTS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.packages' | translate }}
    </div>
    <div
      class="tab"
      (click)="tab = 'history'"
      [class.active]="tab == 'history'"
      *ngIf="Auth.isViewAllowed(['ROLE_HISTORY_DETAILS_VIEW', 'ROLE_ADMIN'])">
      {{ 'orders-front.item.tabs.history' | translate }}
    </div>
    <div class="actions">
      <button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item
          *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"><mat-icon>edit</mat-icon>Zmień
          serwis</button> -->
        <button
          mat-menu-item
          (click)="cancel()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN']) &&
            (order?.state == 'new' ||
              order?.state == 'data_error' ||
              order?.state == 'idle' ||
              order?.state == 'pending' ||
              order?.state == 'assigned' ||
              order?.state == 'no_workshop')
          ">
          <mat-icon>cancel</mat-icon>Anuluj zlecenie
        </button>
        <button mat-menu-item (click)="refresh()">
          <mat-icon>refresh</mat-icon>Odśwież
        </button>
        <button
          mat-menu-item
          (click)="changeService()"
          *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])">
          <mat-icon>change_circle</mat-icon>Zmień serwis
        </button>
        <button
          mat-menu-item
          (click)="updateData()"
          *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])">
          <mat-icon>update</mat-icon>Zaktualizuj dane
        </button>

        <button mat-menu-item (click)="addPreAuth()">
          <mat-icon>approval</mat-icon>Dodaj preautoryzację
        </button>

        <button
          mat-menu-item
          (click)="recalculate()"
          *ngIf="
            (Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN']) &&
              order?.state != 'finished' &&
              order?.state != 'settlement_rejected' &&
              order?.state != 'verification_failed' &&
              order?.state != 'client_rejected' &&
              order?.state != 'client_accepted' &&
              order?.state != 'cancelled' &&
              order?.state != 'data_error' &&
              order?.state != 'settled' &&
              order?.state != 'invoiced' &&
              order?.state != 'verified' &&
              order?.state != 'no_workshop') ||
            (Auth.isViewAllowed(['ROLE_ADMIN']) &&
              order?.state != 'finished' &&
              order?.state != 'settlement_rejected' &&
              order?.state != 'verification_failed' &&
              order?.state != 'client_rejected' &&
              order?.state != 'client_accepted' &&
              order?.state != 'cancelled' &&
              order?.state != 'data_error')
          ">
          <mat-icon>calculate</mat-icon>Przelicz koszty
        </button>
        <button
          mat-menu-item
          (click)="archiveOrder()"
          *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
          <mat-icon>archive</mat-icon>Archiwizuj
        </button>
        <button
          mat-menu-item
          (click)="openLogsOfOrder()"
          *ngIf="Auth.isViewAllowed(['ROLE_ADMIN'])">
          <mat-icon>logo_dev</mat-icon>Logi zlecenia
        </button>
        <hr />
        <button
          mat-menu-item
          (click)="beginRepair()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'assigned'
          ">
          <mat-icon>airline_stops</mat-icon>Rozpoczęcie naprawy
        </button>
        <button
          mat-menu-item
          (click)="endRepair()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'in_repair'
          ">
          <mat-icon>airline_stops</mat-icon>Zakonczenie naprawy
        </button>
        <button
          mat-menu-item
          (click)="finishOrder()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'repaired'
          ">
          <mat-icon>airline_stops</mat-icon>Zakończ zlecenie
        </button>
        <button
          mat-menu-item
          (click)="assignOrder()"
          *ngIf="
            Auth.isViewAllowed(['ROLE_ADMIN']) && order?.state == 'pending'
          ">
          <mat-icon>airline_stops</mat-icon>Przypisz zlecenie
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<app-order-info [order]="order"></app-order-info>
<app-order-details
  *ngIf="tab === 'details'"
  [order]="order"
  [history]="history"></app-order-details>
<app-order-repair
  *ngIf="tab === 'history'"
  [history]="history"></app-order-repair>
<app-order-documents
  [data]="order"
  *ngIf="tab === 'documents'"></app-order-documents>
<app-order-packages
  [order]="order"
  *ngIf="tab === 'packages'"></app-order-packages>
<app-order-costs [order]="order" *ngIf="tab === 'costs'"></app-order-costs>
