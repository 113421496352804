import { ThisReceiver } from '@angular/compiler';
import { Component, Input } from '@angular/core';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { OrdersService } from '../../../services/orders.service';

@Component({
  selector: 'app-order-costs',
  templateUrl: './order-costs.component.html',
  styleUrls: ['./order-costs.component.scss'],
})
export class OrderCostsComponent {
  dataSource: any;
  constructor(
    public Auth: AuthGuard,
    private orderService: OrdersService
  ) {}
  displayedColumns: string[] = [
    'Lp',
    'date',
    'author',
    'transport_cost',
    'service_cost',
    'consultation_cost',
    'parts_cost',
    'own_share',
    'total_netto',
    'total_brutto',
  ];
  details: any = false;
  @Input() order: any;
  ngOnInit(): void {
    this.dataSource = this.order.costsHistory;
    console.log(this.order.costsHistory);
    if (this.order.costs) {
      this.order.costs.courier_sum =
        (this.order?.costs?.courier || 0) + (this.order?.costs?.services || 0);

      this.order.costs.consultation_sum =
        (this.order?.costs?.consultation || 0) +
        (this.order?.costs?.replacement || 0);
    }
  }
}
