import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { ConfirmCancelOrderComponent } from 'src/app/shared/modals/confirm-cancel-order/confirm-cancel-order.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChangeServiceComponent } from 'src/app/shared/modals/change-service/change-service.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmArchiveOrderComponent } from 'src/app/shared/modals/confirm-archive-order/confirm-archive-order.component';
import { FinishOrderModalComponent } from 'src/app/shared/modals/finish-order-modal/finish-order-modal.component';
import { AssignOrderModalComponent } from 'src/app/shared/modals/assign-order-modal/assign-order-modal.component';
import { AddPreauthModalComponent } from 'src/app/shared/modals/add-preauth-modal/add-preauth-modal.component';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {
  public order: any;
  public history: any;
  public showData!: boolean;
  public tab: string = 'details';

  constructor(
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    public Auth: AuthGuard,
    public dialog: MatDialog,
    private translate: TranslateService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.ordersService
      .getOrderById(this.route.snapshot.params['id'])
      .subscribe(res => {
        this.order = res;
        this.ordersService
          .getDataFromIRI(this.order.workshop)
          .subscribe(res => {
            this.order.workshop = res;
          });
      });

    this.ordersService
      .getOrderHistory(this.route.snapshot.params['id'])
      .subscribe((res: any) => {
        this.history = res['hydra:member'];
      });

    this.translate.onLangChange.subscribe(() => {
      this.ordersService
        .getOrderHistory(this.route.snapshot.params['id'])
        .subscribe((res: any) => {
          this.history = res['hydra:member'];
        });
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmCancelOrderComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.ordersService
          .cancelOrder(this.route.snapshot.params['id'])
          .subscribe(res => {
            this.refresh();
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.order.currentValue) {
      this.ordersService.getOrderPreAuth(this.order.id).subscribe(res => {
        this.order.preauthorization = res;
      });
    }
  }

  refresh() {
    this.ngOnInit();
  }

  changeService() {
    const dialogRef = this.dialog.open(ChangeServiceComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result[0] === 'confirm') {
        this.ordersService
          .updateServiceForOrder(this.order.id, result[1])
          .subscribe();
        this.refresh();
      }
    });
  }

  recalculate() {
    this.ordersService
      .recalculateOrder(this.route.snapshot.params['id'])
      .subscribe(res => {
        if (
          res ===
          'Order has no costs stored - recalculation of margins and service fee is not possible'
        ) {
          this._snackBar.open(
            'Zamówienie nie ma zapisanych kosztów - przeliczenie marż i opłat serwisowych nie jest możliwe',
            'Zamknij',
            {}
          );
        }

        if (res == 'Costs successfully recalculated in DRS.') {
          this._snackBar.open(
            'Koszty pomyślnie przkalkulowane w DRS',
            'Zamknij',
            {}
          );
        }

        if (
          res != 'Costs successfully recalculated in DRS.' &&
          res !=
            'Order has no costs stored - recalculation of margins and service fee is not possible'
        ) {
          this._snackBar.open(res.toLocaleString() as string, 'Zamknij', {});
        }
      });
    this.ngOnInit();
  }

  updateData() {
    this.ordersService.updateDataOfOrder(this.order.id).subscribe(res => {
      if (res == 'Order data has been successfully updated in DRS') {
        this._snackBar.open(
          'Dane pomyślnie zaktualizowane w DRS',
          'Zamknij',
          {}
        );
      } else if (
        res ==
        'Order has no costs stored - recalculation of margins and service fee is not possible'
      ) {
        this._snackBar.open(
          'Zamówienie nie ma zapisanych kosztów - przeliczenie marż i opłat serwisowych nie jest jużwe',
          'Zamknij',
          {}
        );
      } else {
        this._snackBar.open(res.toLocaleString() as string, 'Zamknij', {});
      }

      this.ngOnInit();
    });
  }
  archiveOrder() {
    const dialogRef = this.dialog.open(ConfirmArchiveOrderComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.ordersService
          .archiveOrder(this.route.snapshot.params['id'])
          .subscribe(res => {
            if (res === 'Order has been successfully moved to archive') {
              this._snackBar.open(
                'Zlecenie zostało zarchiwizowane',
                'Zamknij',
                {}
              );
            } else if (
              res ===
              'Order is already in archive - it is impossible to move it there again'
            ) {
              this._snackBar.open(
                'Zlecenie jest już w archiwum - nie jest ono możliwe do przeniesiania do archiwum',
                'Zamknij',
                {}
              );
            } else {
              this._snackBar.open(
                res.toLocaleString() as string,
                'Zamknij',
                {}
              );
            }
            this.ngOnInit();
          });
      }
    });
  }

  beginRepair() {
    this.ordersService.sendBeginRepair(this.order.id).subscribe(res => {
      this._snackBar.open('Naprawa została rozpoczęta', 'Zamknij', {});

      setTimeout(() => {
        this.ngOnInit();
      }, 2000);
    });
  }

  endRepair() {
    this.ordersService.sendEndRepair(this.order.id).subscribe(res => {
      this._snackBar.open('Naprawa została zakończona', 'Zamknij', {});

      setTimeout(() => {
        this.ngOnInit();
      }, 2000);
    });
  }

  addPreAuth() {
    const dialogRef = this.dialog.open(AddPreauthModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.code) {
        const data = {
          code: result.code,
          action: result.action,
          diagnosis: result.diagnosis,
          actionCode: result.actionCode,
          estimatedCost: result.estimatedCost,
        };
        this.ordersService.sendPreAuth(this.order.id, data).subscribe(res => {
          this._snackBar.open('Preautoryzacja została dodana', 'Zamknij', {});

          setTimeout(() => {
            this.ngOnInit();
          }, 2000);
        });
      }
    });
  }

  finishOrder() {
    const dialogRef = this.dialog.open(FinishOrderModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.code) {
        const data = {
          code: result.code,
          action: result.action,
          repairDescription: result.repairDescription,
        };
        this.ordersService
          .sendFinishOrder(this.order.id, data)
          .subscribe(res => {
            this._snackBar.open('Zlecenie zostało zakończone', 'Zamknij', {});

            setTimeout(() => {
              this.ngOnInit();
            }, 2000);
          });
      }
    });
  }

  assignOrder() {
    const dialogRef = this.dialog.open(AssignOrderModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.name) {
        this.ordersService.assignOrder(this.order.id, result).subscribe(res => {
          this._snackBar.open('Zlecenie zostało przypisane', 'Zamknij', {});

          setTimeout(() => {
            this.ngOnInit();
          }, 2000);
        });
      }
    });
  }

  openLogsOfOrder() {
    window.open('/logs/' + this.order.faultNumber, '_blank');
  }
}
